.searchInput {
    width: 100%;
    margin-right: 16px;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 16px;
}

.button {
    font-size: 0;
    margin-right: 16px;
}

.button > span {
    margin-inline-end: 0 !important;
}

.button span:first-of-type {
    font-size: 14px;
}

@media screen and (min-width: 576px) {
    .button {
        font-size: 14px;
    }

    .button > span {
        margin-inline-end: 8px !important;
    }
}

@media screen and (min-width: 768px) {
    .searchInput {
        width: 411px;
        margin-right: 24px;
    }

    .button {
        margin-right: 24px;
    }
}

@media screen and (min-width: 1200px) {
    .panel {
        flex-direction: row;
        justify-content: space-between;
    }
}
