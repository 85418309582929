.header {
    position: relative;
    min-height: 72px;
    height: auto;
    padding: 8px 32px 8px 48px;
    background: white;
    display: flex;
    flex-direction: column-reverse;
    line-height: inherit;
}

.layout {
    min-height: 100vh;
    position: relative;
}

.layout .sider {
    position: absolute;
    height: 100%;
    z-index: 1000;
}

.title {
    margin-top: 16px;
}

@media screen and (min-width: 992px) {
    .layout .sider {
        position: relative;
        height: auto;
    }
}

@media screen and (min-width: 576px) {
    .header {
        padding: 0 32px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .title {
        margin-top: 0;
    }
}
