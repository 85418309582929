.layout {
    min-height: 100vh;
    flex-direction: column-reverse;
}

.layout .sider {
    width: 100%;
    padding: 24px;
}

.logo {
    width: 64px;
}

@media screen and (min-width: 768px) {
    .layout {
        flex-direction: row;
    }

    .layout .sider {
        width: 50%;
    }

    .logo {
        width: 128px;
    }
}
