/* This file over-rides some Antd styles that can not be changed via theme customization */

.ant-menu-item-selected {
    border-right: 3px #722ed1 solid;
}

.ant-menu-submenu-title {
    margin: 0 !important;
    width: 100% !important;
    border-radius: 0 !important;
}

.pageLoader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
